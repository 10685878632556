import { useCallback, useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  AiOutlineEye,
  AiOutlineCalendar,
  AiOutlineCopy,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import CreateNewBot from "./Dashboard_components/createNewBot";
import CreateBotIcon from "../assets/chatbot/CreateBotIcon.png";

import { ReactComponent as MessageIcon } from "../assets/chatbot/message.svg";
import { ReactComponent as CloseCircleIcon } from "../assets/chatbot/closecircle.svg";
import SourcesIcon from "../assets/chatbot/SourceIcon.svg";
import withAccessControl from "../utils/withAccessControl";

import { PricingChartModel } from "./PricingChartModel";
import { AppContext } from "../utils/AppContext";
import FetchWithToken from "../utils/FetchWithToken";
import { t } from "i18next";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const Bots = ({ hasAccess, handleCallback, details, Package }) => {
  const { t, i18n } = useTranslation();
  //api
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // const { globalTeam, setGlobalTeam } = useContext(AppContext);
  const [allBotsData, setAllBotsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedBot, setSelectedBot] = useState(null);

  const [showPricingModal, setShowPricingModal] = useState(false);

  // const { globalTeam, setGlobalTeam } = useContext(AppContext);

  const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
  const selectedTeam = JSON.parse(
    localStorage.getItem("docsbot-users")
  ).selectedTeam;
  const [urlParams, seturlParams] = useState(
    queryString.parse(window.location.search)
  );

  const toast = useToast();
  const navigate = useNavigate();

  const handleDeleteOpenModal = (bot) => {
    setSelectedBot(bot);
    setShowConfirmationModal(true);
  };

  const handleOpenModal = (data) => {
    if (data) {
      setShowModal(true);
    } else if (data == false) {
      setShowPricingModal(true);
    }
    // toast({
    //     title: 'Update your plan',
    //     status: 'info',
    //     duration: 4000,
    //     isClosable: true,
    // });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchData();
    handleCallback();
  };
  const handleCancel = () => {
    setShowModal(false);
    handleCallback();
  };
  const fetchData = useCallback(() => {
    // Wrap the fetchData function with useCallback to memoize it
    setIsLoading(true);
    FetchWithToken(
      BASE_URL + `/chatbots?team_id=${selectedTeam.team_id}`,
      "GET"
    ).then((data) => {
      setIsLoading(false);
      setAllBotsData(data.bots);
    });
  }, []);

  const deleteBot = (botId) => {
    const bot = allBotsData.find((data) => data.bot_id === botId);
    handleDeleteOpenModal(bot);
  };

  const handleDeleteConfirmation = () => {
    const botId = selectedBot.bot_id;
    const user_id = JSON.parse(localStorage.getItem("docsbot-users")).user_id;
    const data = {
      bot_id: botId,
      user_id: user_id,
    };

    FetchWithToken(BASE_URL + "/chatbot/deletebot", "DELETE", data)
      .then((data) => {
        fetchData();
        handleCallback();
      })
      .catch((err) => {
        toast({
          title: "An error has occoured!",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        console.log(err);
      });

    setShowConfirmationModal(false);
  };

  const handleCardClick = (botdata) => {
    if (urlParams.lang) {
      navigate(
        `/admin-dashboard/chatbots/${botdata.bot_name}?botId=${botdata.bot_id}&lang=${urlParams.lang}`,
        { state: { botData: botdata } }
      );
    } else {
      navigate(
        `/admin-dashboard/chatbots/${botdata.bot_name}?botId=${botdata.bot_id}`,
        { state: { botData: botdata } }
      );
    }
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Language detect for the Bot details page
  useEffect(() => {
    seturlParams(queryString.parse(window.location.search));
    i18n.changeLanguage(urlParams.lang ? urlParams.lang : "en");
    // localStorage.setItem("language", lang);
  }, [urlParams]);

  //
  const userRole = selectedTeam.user_role;
  const isDisabled = userRole === "owner" ? false : true;

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
          <Spinner color="blue.500" />
        </Box>
      ) : (
        <>
          {!allBotsData?.length > 0 && (
            <Box
              padding={" 22px 41px"}
              border={"1px dashed #408CFD"}
              maxW={"400px"}
              bg={"rgba(64, 140, 253, 0.04)"}
              cursor={"pointer"}
              borderRadius={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
              margin="50px auto"
            >
              <Image src={CreateBotIcon} alt="BotIcon" />
              <Text fontSize={"17px"} fontWeight={700} as={"b"}>
                {t("Chatbots.Create A New Chatbot")}
              </Text>
              <Text
                fontSize={"15px"}
                fontWeight={500}
                textAlign={"center"}
                color="#979797"
              >
                {t(
                  "Chatbots.Train a new chatbot with your own documentation, website links or content"
                )}
              </Text>
              <Button
                onClick={() => handleOpenModal(hasAccess?.BOTS.enabled)}
                mt={"20px"}
                bg={"rgba(64, 140, 253, 0.12)"}
                borderRadius={"9px"}
                w={"157px"}
                h={"35px"}
                _hover={{ bg: "#408CFD", color: "white" }}
              >
                {t("Chatbots.create_new")}
                {/* Create New */}
              </Button>
            </Box>
          )}
          <SimpleGrid
            spacing={4}
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            {allBotsData?.length > 0 && (
              <Box
                padding={4}
                border={"1px dashed #408CFD"}
                maxH={"277px"}
                bg={"rgba(64, 140, 253, 0.04)"}
                cursor={"pointer"}
                borderRadius={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign={"center"}
              >
                <Image src={CreateBotIcon} alt="BotIcon" />
                <Box mt={"18px"}>
                  <Text fontSize={"16px"} fontWeight={700} as={"b"}>
                    {t("Chatbots.Create A New Chatbot")}
                  </Text>
                  <Text
                    mt={"8px"}
                    fontSize={"12px"}
                    fontWeight={500}
                    textAlign={"center"}
                    color="#979797"
                  >
                    {t(
                      "Chatbots.Train a new chatbot with your own documentation, website links or content"
                    )}
                  </Text>
                </Box>
                <Button
                  onClick={() => handleOpenModal(hasAccess?.BOTS.enabled)}
                  fontSize={"14px"}
                  mt={"auto"}
                  bg={"rgba(64, 140, 253, 0.12)"}
                  borderRadius={"50px"}
                  w={"175px"}
                  h={"31px"}
                  _hover={{ bg: "#408CFD", color: "white" }}
                >
                  {t("Chatbots.create_new")}
                  {/* Create New */}
                </Button>
              </Box>
            )}
            {allBotsData?.map((data, index) => (
              <Card key={index} borderRadius={12} p={0}>
                <CardHeader
                  pb={0}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Button p={1}>
                    <MessageIcon />
                  </Button>
                  <CloseCircleIcon
                    cursor={"pointer"}
                    onClick={() => deleteBot(data?.bot_id)}
                  />
                </CardHeader>
                <CardBody pt={2}>
                  <Box>
                    <Text fontSize={"18px"} fontWeight={700} color={"#212121"}>
                      {data?.bot_name}
                    </Text>
                    <Text
                      mt={"5px"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#999999"}
                    >
                      {new Date(data?.created_ts).toLocaleDateString()}
                    </Text>
                    <Box mt={"16px"} mb={"35px"}>
                      <Badge
                        bg={data?.source_count > 0 ? " #E2FBE8 ;" : "#FDF8C9"}
                        border={"1px dashed #8A6131"}
                        fontSize={"12px"}
                        fontWeight={500}
                        borderRadius={"44px"}
                        p={"5px 0px"}
                        h={"27px"}
                        textAlign={"center"}
                        w={"100%"}
                      >
                        {data?.source_count > 0
                          ? t("Chatbots.ready_sources")
                          : t("Chatbots.awaiting_sources")}
                      </Badge>
                      <Box display={"flex"} alignItems={"baseline"} gap={3}>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1.5}
                          fontSize={{ base: "12px", md: "16px" }}
                        >
                          {data?.privacy === "public" ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                          <Text
                            fontSize={"13px"}
                            fontWeight={500}
                            color={" #212121"}
                          >
                            {data?.privacy}
                          </Text>
                        </Box>
                        <Box
                          mt={"12px"}
                          display="flex"
                          alignItems="center"
                          gap={1.5}
                          fontSize={{ base: "12px", md: "16px" }}
                        >
                          <Image src={SourcesIcon} alt="SourcesIcon" />
                          <Text
                            fontSize={"13px"}
                            fontWeight={500}
                            color={" #212121"}
                          >
                            {/* Sources: {data?.source_count} */}
                            {t("Chatbots.sources")}: {data?.source_count}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      onClick={() => handleCardClick(data)}
                      w={"100%"}
                      h={"31px"}
                      border={"1px solid rgba(33, 33, 33, 0.21)"}
                      borderRadius={"80px"}
                      bg={""}
                      _hover={{ bg: "#002298", color: "white" }}
                    >
                      {t("Chatbots.view")}
                    </Button>
                  </Box>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </>
      )}

      <CreateNewBot
        isOpen={showModal}
        onClose={handleCloseModal}
        onCancel={handleCancel}
        hasAccess={hasAccess}
      />

      {/* //Delete modal */}
      <Modal
        size={"4xl"}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0} fontSize={"18px"} color={"#212121"}>
            Delete Bot {selectedBot?.bot_name}
          </ModalHeader>
          <ModalBody pt={0}>
            <Text fontSize={"13px"} color={"#212121"} opacity={0.6}>
              Once you delete this bot, all indexed sources will be deleted and
              any embedded chats for this bot will no longer work.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={"start"}>
            <Button
              mr={"10px"}
              w={"129px"}
              h={"34px"}
              border={"1px solid rgba(33, 33, 33, 0.15)"}
              borderRadius={"65px"}
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button
              isDisabled={isDisabled}
              _hover={""}
              w={"129px"}
              h={"34px"}
              border={"1px solid rgba(33, 33, 33, 0.15)"}
              borderRadius={"65px"}
              bg={"#D4443B"}
              color={"white"}
              mr={3}
              onClick={handleDeleteConfirmation}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* modals */}
      {showPricingModal && (
        <PricingChartModel
          isOpen={showPricingModal}
          onClose={handleClosePricingModal}
        />
      )}
    </Box>
  );
};

const BotsWithAccessControl = withAccessControl(Bots);
export default BotsWithAccessControl;
