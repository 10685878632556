import { useCallback, useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowRight, MdOutlineQrCode } from 'react-icons/md';
import { FaInfo } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import WhatsAppIntegration from './Integration_components/WhatsAppIntegration';
import WhatsAPPIcon from '../assets/integration/whatsapp.svg';
import SlackIcon from '../assets/integration/slack.svg';
import MessengerIcon from '../assets/integration/messenger.svg';
import LineIcon from '../assets/integration/line.svg';
import TelegramIcon from '../assets/integration/telegram.svg';
import InstagramIcon from '../assets/integration/instagram.png';
import ShopeeIcon from '../assets/integration/shopee.png';
import LazadaIcon from '../assets/integration/lazada.png';
import withAccessControl from '../utils/withAccessControl';
import { PricingChartModel } from './PricingChartModel';
import FetchWithToken from '../utils/FetchWithToken';
import LineGuideModal from './Integration_components/LineGuideModal';
import LineIntegration from './Integration_components/LineIntegration';
import { useToast } from '@chakra-ui/react';

const Integration = ({ hasAccess, handleCallback }) => {
  const { t, i18n } = useTranslation();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const userData = JSON.parse(localStorage.getItem('docsbot-users'));
  const [QRData, setQRData] = useState();

  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [allBotsData, setAllBotsData] = useState([]);
  const {
    isOpen: isOpenLineGuide,
    onOpen: onOpenLineGuide,
    onClose: onCloseLineGuide,
  } = useDisclosure();
  const {
    isOpen: inOpenLineForm,
    onOpen: onOpenLineForm,
    onClose: onCloseLineForm,
  } = useDisclosure();

  const selectedTeam = JSON.parse(
    localStorage.getItem('docsbot-users')
  ).selectedTeam;

  const handleWhatsAppOpenModal = () => {
    hasAccess?.WHATSAPP_INTEGRATIONS?.enabled
      ? setShowWhatsAppModal(true)
      : setShowPricingModal(true);
  };

  const handleWhatsAppCloseModal = () => {
    setShowWhatsAppModal(false);
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  const fetchData = useCallback(() => {
    FetchWithToken(
      BASE_URL + `/chatbots?team_id=${selectedTeam.team_id}`,
      'GET'
    ).then((data) => {
      setAllBotsData(data.bots);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    (() => {
      FetchWithToken(
        process.env.REACT_APP_BASE_URL +
          '/integrations/line?user_id=' +
          userData.user_id,
        'GET'
      )
        .then((data) => {
          if (!data.error) setQRData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  const disconnect = (provider) => {
    FetchWithToken(
      process.env.REACT_APP_BASE_URL +
        '/integrations/' +
        provider +
        '?user_id=' +
        userData.user_id,
      'DELETE'
    ).then((data) => {
      setQRData(undefined);
    });
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: 'ID Copied!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Copy Failed!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  console.log('QRData', QRData);

  return (
    <Box bg={'#FFFFFF'} borderRadius={'10px'} height={'100%'}>
      <Box p={'10px 20px'}>
        <Text as={'b'}>{t('Integrations.Connected Apps')}</Text>
        <Text mt={1} fontSize="md">
          {t(
            'Integrations.Integrate with your favorite apps and supercharge your workflow'
          )}
        </Text>
        <Divider mt={3} />
        <SimpleGrid
          paddingTop={5}
          spacing={6}
          templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
        >
          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={LineIcon} alt="BotIcon" width="50px" />
              <Box display={'flex'} gap="10px">
                {QRData !== undefined && (
                  <Button
                    bg={''}
                    border={'1px solid rgba(33, 33, 33, 0.21)'}
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'full'}
                    _hover={{ bg: 'rgba(33, 33, 33, 0.21)', color: 'white' }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    padding={'0'}
                    onClick={() => window.open(QRData?.line_bot_url, '_blank')}
                  >
                    <MdOutlineQrCode fill="#718096" />
                  </Button>
                )}
                <Button
                  onClick={onOpenLineGuide}
                  bg={''}
                  border={'1px solid rgba(33, 33, 33, 0.21)'}
                  height={'40px'}
                  width={'40px'}
                  borderRadius={'full'}
                  _hover={{ bg: 'rgba(33, 33, 33, 0.21)', color: 'white' }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <FaInfo fill="#718096" />
                </Button>
              </Box>
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Line')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Extend your services to Line users with our chatbot integration'
                )}
              </Text>
              {QRData?.line_bot_url && (
                <Text fontSize={13} fontWeight={500} color="gray.500">
                  {t('Integrations.Bot ID')}:
                  <Tooltip label="Click to Copy!" aria-label="Click to Copy!">
                    <Text
                      display="inline"
                      ml="4px"
                      bg="gray.200"
                      fontWeight="semibold"
                      p="2px 8px"
                      rounded="md"
                      cursor="pointer"
                      onClick={() => copyText(QRData?.basic_id)}
                    >
                      {QRData?.basic_id}
                    </Text>
                  </Tooltip>
                </Text>
              )}
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
                rightIcon={
                  !QRData?.line_bot_url && <MdOutlineKeyboardArrowRight />
                }
                onClick={
                  QRData?.line_bot_url
                    ? () => disconnect('line')
                    : onOpenLineForm
                }
              >
                {QRData?.line_bot_url
                  ? t('Integrations.Disconnect')
                  : t('Integrations.Connect')}
              </Button>
            </CardFooter>
          </Card>

          {/* <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={WhatsAPPIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.WhatsApp')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  "Integrations.Connect with billions of users on the world's by integrating with WhatsApp"
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                onClick={() => handleWhatsAppOpenModal()}
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
                rightIcon={<MdOutlineKeyboardArrowRight />}
              >
                {t('Integrations.Connect')}
              </Button>
            </CardFooter>
          </Card> */}

          {/* <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={SlackIcon} alt="BotIcon" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Slack')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  "Integrations.Supercharge your team's productivity by integrating our chatbot into Slack"
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card> */}

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={MessengerIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Messenger')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Messenger by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card>

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={InstagramIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Instagram')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Messenger by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card>

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={LazadaIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Lazada')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Lazada by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card>

          <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={ShopeeIcon} alt="BotIcon" width="50px" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Shopee')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Unlock the power of Shopee by integrating our advanced chatbot'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card>

          {/* <Card p={'20px 25px'} shadow={0} variant={'outline'}>
            <CardHeader p={0} display="flex" justifyContent="space-between">
              <Image src={TelegramIcon} alt="BotIcon" />
            </CardHeader>
            <CardBody
              m={'20px 0 10px 0'}
              p={0}
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={2}
            >
              <Text fontWeight={700}>{t('Integrations.Telegram')}</Text>
              <Text fontSize={13} fontWeight={500} color="gray.500">
                {t(
                  'Integrations.Extend your services to Telegram users with our chatbot integration'
                )}
              </Text>
            </CardBody>
            <CardFooter p={0}>
              <Button
                bg={''}
                w={'100%'}
                h={'34px'}
                border={'1px solid rgba(33, 33, 33, 0.21)'}
                isDisabled
                // _hover={{ bg: "var(--Web-color-orange)", color: "white" }}
                _hover={{ bg: '#002298', color: 'white' }}
              >
                {t('Integrations.Coming Soon')}
              </Button>
            </CardFooter>
          </Card> */}
        </SimpleGrid>
        <WhatsAppIntegration
          isOpen={showWhatsAppModal}
          onClose={handleWhatsAppCloseModal}
          allBotsData={allBotsData}
          handleCallback={handleCallback}
        />
      </Box>
      <LineGuideModal isOpen={isOpenLineGuide} onClose={onCloseLineGuide} />
      <LineIntegration
        isOpen={inOpenLineForm}
        onClose={onCloseLineForm}
        setQRData={setQRData}
      />

      {showPricingModal && (
        <PricingChartModel
          isOpen={showPricingModal}
          onClose={handleClosePricingModal}
        />
      )}
    </Box>
  );
};

const IntegartionsWithAccessControl = withAccessControl(Integration);
export default IntegartionsWithAccessControl;
