import { SmallAddIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Box,
  Input,
  Link,
  IconButton,
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  Switch,
  SimpleGrid,
  ModalCloseButton,
  Flex,
  ModalHeader,
  Avatar,
  InputGroup,
  InputRightElement,
  Heading,
  AvatarBadge,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BsFillChatFill, BsQuestionLg } from 'react-icons/bs';
import { FaRobot } from 'react-icons/fa';
import { RiDeleteBin6Line, RiSendPlane2Fill } from 'react-icons/ri';
import { BiSupport, BiBookBookmark, BiInfoCircle } from 'react-icons/bi';
import { IoIosChatbubbles } from 'react-icons/io';
import FetchWithToken from '../../../utils/FetchWithToken';
import { color } from 'framer-motion';

const WidgetCustomize = ({ isOpen, onClose, botData, hasAccess }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const user_id = JSON.parse(localStorage.getItem('docsbot-users')).user_id;
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    privacy: 'public',
    openaiModal: 'gpt3',
    language: 'english',
  });
  const [isSubmitting, setisSubmitting] = useState(false);
  const [copied, setCopied] = useState(false);
  const textRef = useRef(null);
  const [selectedIcon, setSelectedIcon] = useState('BsFillChatFill');
  const [selectedAvatar, setSelectedAvatar] = useState('None');
  const [buttonAlignment, setButtonAlignment] = useState('Left');
  const [buttonTextToggle, setButtonTextToggle] = useState(false);
  const [brandingToggle, setBrandingToggle] = useState(true);
  const [heading, setHeading] = useState('Welcome');
  const [subHeading, setSubHeading] = useState('Ask me anything');
  const [popFirstMessage, setpopFirstMessage] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [supportLink, setSupportLink] = useState('');
  const [allowDomain, setAllowDomain] = useState([]);
  const [buttoncolor, setbuttoncolor] = useState('#ff8321');
  const [headercolor, setheadercolor] = useState('#408CFD');
  const [widgetcolor, setwidgetcolor] = useState('#ffffff');
  const [botTextcolor, setbotTextcolor] = useState('#000000');
  const [botBackgroundcolor, setbotBackgroundcolor] = useState('#ffffff');
  const [userTextcolor, setuserTextcolor] = useState('#ffffff');
  const [userBackgroundcolor, setuserBackgroundcolor] = useState('#408CFD');
  const [citation, setCitation] = useState('true');

  const [supportButtonText, setSupportButtonText] = useState('');
  const [firstMessage, setFirstMessage] = useState('');

  //First question
  const [firstQuestion, setFirstQuestion] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [deletedQuestions, setDeletedQuestions] = useState([]);

  const toast = useToast();

  const iconMap = {
    BsFillChatFill: BsFillChatFill,
    IoIosChatbubbles: IoIosChatbubbles,
    FaRobot: FaRobot,
    BiSupport: BiSupport,
    BiBookBookmark: BiBookBookmark,
    BsQuestionLg: BsQuestionLg,
  };

  function IconComponent({ iconName }) {
    const Icon = iconMap[iconName];
    return Icon ? <Icon size={'25px'} /> : null;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      bot_id: botData.bot_id,
      heading: heading,
      subheading: subHeading,
      first_message: firstMessage,
      first_message_pop_up: popFirstMessage,
      support_link: supportLink,
      support_button_text: supportButtonText,
      allowed_domain: allowDomain,
      header_color: headercolor,
      button_color: buttoncolor,
      widget_color: widgetcolor,
      widget_icon: selectedIcon,
      bot_avatar: selectedAvatar,
      citations: citation,
      widget_alignment: buttonAlignment,
      show_button_text: buttonTextToggle,
      show_branded_text: brandingToggle,
      button_text: buttonText,
      bot_text_color: botTextcolor,
      bot_background_color: botBackgroundcolor,
      user_text_color: userTextcolor,
      user_background_color: userBackgroundcolor,
      questions: firstQuestion,
      delete_ques: deletedQuestions,
    };
    setisSubmitting(true);

    FetchWithToken(BASE_URL + '/widget', 'POST', data).then((data) => {
      setisSubmitting(false);
      toast({
        title: 'Data saved successfully',
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
      onClose();
    });
  };

  const handleHeading = (event) => {
    setHeading(event.target.value);
  };
  const handleSubHeading = (event) => {
    setSubHeading(event.target.value);
  };
  const handlePopFirstMessageSwitch = () => {
    setpopFirstMessage((prevShowText) => !prevShowText);
  };
  const handleButtonText = (event) => {
    setButtonText(event.target.value);
  };
  const handleSupportLink = (event) => {
    setSupportLink(event.target.value);
  };
  const handleSupportButtonText = (event) => {
    setSupportButtonText(event.target.value);
  };
  const handleFirstMessage = (event) => {
    setFirstMessage(event.target.value);
  };
  const handleAllowDomain = (event) => {
    const inputtedDomains = event.target.value;
    const domains = inputtedDomains.split(',').map((domain) => domain.trim());
    setAllowDomain(domains);
  };

  const handleHeaderColorChange = (event) => {
    setheadercolor(event.target.value);
  };
  const handleButtonColorChange = (event) => {
    setbuttoncolor(event.target.value);
  };

  const handleWidgetColorChange = (event) => {
    setwidgetcolor(event.target.value);
  };
  const handlebotTextColorChange = (event) => {
    setbotTextcolor(event.target.value);
  };
  const handlebotBackgroundColorChange = (event) => {
    setbotBackgroundcolor(event.target.value);
  };
  const handleuserTextColorChange = (event) => {
    setuserTextcolor(event.target.value);
  };
  const handleuserBackgroundColorChange = (event) => {
    setuserBackgroundcolor(event.target.value);
  };
  const handleCopy = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => setCopied(true))
      .catch(() => setCopied(false));
  };
  const handleToggleSwitch = () => {
    setButtonTextToggle((prevShowText) => !prevShowText);
  };
  const handleToggleBranding = () => {
    setBrandingToggle((prevShowText) => !prevShowText);
  };
  const handleIconClick = (iconName) => {
    setSelectedIcon(iconName);
  };

  const renderButton = (iconName, icon) => {
    const isSelected = selectedIcon === iconName;

    return (
      <IconButton
        size="lg"
        fontSize="25px"
        key={iconName}
        icon={icon}
        variant={'outline'}
        color={isSelected ? 'blue' : 'gray.400'}
        borderColor={isSelected ? 'blue' : 'gray.200'}
        _hover={{ bg: 'lightblue', color: 'white' }}
        aria-label={iconName}
        onClick={() => handleIconClick(iconName)}
        margin={1}
        borderRadius={50}
      />
    );
  };

  const handleAvatarClick = (iconName) => {
    setSelectedAvatar(iconName);
  };
  const renderAvatar = (iconName, icon) => {
    const isSelected = selectedAvatar === iconName;
    if (iconName === 'None') {
      return (
        <Button
          size="lg"
          fontSize="16px"
          key={iconName}
          variant="outline"
          color={isSelected ? 'blue' : 'gray.400'}
          borderColor={isSelected ? 'blue' : 'gray.200'}
          _hover={{ bg: 'lightblue', color: 'white' }}
          onClick={() => handleAvatarClick(iconName)}
          margin={1}
          borderRadius={'61px'}
          p={'4px'}
        >
          None
        </Button>
      );
    }

    return (
      <IconButton
        size="lg"
        fontSize="25px"
        key={iconName}
        icon={icon}
        variant="outline"
        color={isSelected ? 'blue' : 'gray.400'}
        borderColor={isSelected ? 'blue' : 'gray.200'}
        _hover={{ bg: 'lightblue', color: 'white' }}
        aria-label={iconName}
        onClick={() => handleAvatarClick(iconName)}
        margin={1}
        borderRadius={50}
      />
    );
  };

  const removeQuestion = (ques_id) => {
    const deletedQuestion = firstQuestion.find(
      (question) => question.ques_id === ques_id
    );
    if (deletedQuestion) {
      setFirstQuestion(
        firstQuestion.filter((question) => question.ques_id !== ques_id)
      );
      setDeletedQuestions([...deletedQuestions, deletedQuestion]);
    }
  };
  const addQuestion = (newQuestion) => {
    const updatedQuestion = {
      bot_id: botData.bot_id,
      text: newQuestion.text,
    };

    setFirstQuestion((prevQuestions) => [...prevQuestions, updatedQuestion]);
    setNewQuestion('');
  };

  const updateQuestion = (questionId, updatedQuestion) => {
    setFirstQuestion((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.ques_id === questionId) {
          return { ...question, text: updatedQuestion };
        }
        return question;
      });
    });
  };
  const setFirstQuestionFormat = (questions) => {
    const transformedQuestions = questions.map((question) => {
      return {
        bot_id: question.bot_id,
        ques_id: question.ques_id,
        text: question.question,
        source_id: question.source_id,
      };
    });
    setFirstQuestion(transformedQuestions);
  };

  const HoverTooltip = ({ message }) => {
    return (
      <Tooltip
        p={3}
        fontSize={'13px'}
        borderRadius={' 10px 10px 10px 0px'}
        label={message}
        placement="top-start"
        hasArrow
      >
        <Box display="inline-block">
          <BiInfoCircle />
        </Box>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (isOpen) {
      if (botData) {
        //console.log(botData)
        setFormData({
          bot_name: botData.bot_name,
          description: botData.description,
          privacy: botData.privacy,
          openai_model: 'gpt3',
          bot_language: botData.bot_language,
        });
        FetchWithToken(
          BASE_URL + '/chatbot/questions?bot_id=' + botData.bot_id,
          'GET'
        ).then((data) => {
          setFirstQuestionFormat(data.quesions);
        });
      }
    }
  }, [botData, isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (botData) {
        //console.log(botData)
        setFormData({
          bot_name: botData.bot_name,
          description: botData.description,
          privacy: botData.privacy,
          openai_model: 'gpt3',
          bot_language: botData.bot_language,
        });

        FetchWithToken(
          BASE_URL + '/widget?bot_id=' + botData.bot_id,
          'GET'
        ).then((data) => {
          setSelectedIcon(data.widget['widget-icon']);
          setSelectedAvatar(data.widget['bot-avatar']);
          setButtonAlignment(data.widget['widget-alignment']);
          setButtonTextToggle(data.widget['show-button-text']);
          setBrandingToggle(
            data.widget['show-branded-text'] != undefined
              ? data.widget['show-branded-text']
              : true
          );
          setHeading(data.widget['heading']);
          setSubHeading(data.widget['subheading']);
          setpopFirstMessage(data.widget['first-message-pop-up']);
          setButtonText(data.widget['button-text']);
          setSupportLink(data.widget['support-link']);
          setheadercolor(data.widget['header-color']);
          setbuttoncolor(data.widget['button-color']);
          setwidgetcolor(data.widget['widget-color']);
          setbotTextcolor(data.widget['bot-text-color']);
          setbotBackgroundcolor(data.widget['bot-background-color']);
          setuserTextcolor(data.widget['user-text-color']);
          setuserBackgroundcolor(data.widget['user-background-color']);
          setCitation(data.widget['citations']);
          setSupportButtonText(data.widget['support-button-text']);
          setFirstMessage(data.widget['first-message']);
        });
      }
    }
  }, [botData, isOpen]);

  return (
    <Modal size={'4xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent marginTop={5}>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody pb={6}>
            <Box mt={4}>
              <Box w={{ base: '100%', md: '50%' }} mb={'10px'}>
                <Text fontSize={21} fontWeight={700}>
                  Customize the Widget
                </Text>
                <Text fontSize={13}>
                  Personalize the behavior and appearance of your chat widget to
                  match your preferences. Please allow a few minutes for any
                  changes to reflect on your site.
                </Text>
              </Box>
              <Box
                display={'flex'}
                flexDirection={{ base: 'column-reverse', md: 'row' }}
                justifyContent={'space-between'}
                gap={4}
              >
                <Box w={'100%'}>
                  <Box mt={'15px'}>
                    <Text fontSize={'16px'} mb={'14px'} fontWeight={700}>
                      Header
                    </Text>
                    <Box>
                      <Text fontWeight={500} fontSize={14} mb={'8px'}>
                        Heading
                      </Text>
                      <Input
                        fontSize={13}
                        placeholder="Welcome"
                        required
                        name="HeadingText"
                        value={heading}
                        onChange={handleHeading}
                      />
                    </Box>
                    <Box mt={'16px'}>
                      <Text fontWeight={500} fontSize={14} mb={'8px'}>
                        SubHeading
                      </Text>
                      <Input
                        fontSize={13}
                        placeholder="Ask Me Anything"
                        required
                        name="buttonText"
                        value={subHeading}
                        onChange={handleSubHeading}
                      />
                    </Box>
                  </Box>
                  <Box mt={'38px'}>
                    <Text fontWeight={500} fontSize={14}>
                      First Message
                    </Text>
                    <Input
                      mt={'8px'}
                      fontSize={13}
                      placeholder="Hi 👋 ! What can i do for you today?"
                      required
                      value={firstMessage}
                      name="SupportLink"
                      onChange={handleFirstMessage}
                    />
                    <Flex
                      alignItems={'center'}
                      mt={'6px'}
                      gap={3}
                      align={'end'}
                    >
                      <Text fontSize={14} color={'#212121'} opacity={0.5}>
                        Pop Up:
                      </Text>
                      <Switch
                        colorScheme="orange"
                        id="isRequired"
                        isChecked={popFirstMessage}
                        onChange={handlePopFirstMessageSwitch}
                      />
                      <HoverTooltip message="Show a popup message as soon as your visitors visit your website. Increase chat interaction.!" />
                    </Flex>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14}>
                      Support Link
                    </Text>
                    <Text
                      mt={'7px'}
                      color={'#212121'}
                      opacity={0.5}
                      fontWeight={600}
                      fontSize={12}
                    >
                      This link will appear after the bot replies. Optional,
                      leave blank to hide.
                    </Text>
                    <Input
                      mt={'8px'}
                      fontSize={13}
                      placeholder="https://example.com/support/"
                      value={supportLink}
                      name="SupportLink"
                      onChange={handleSupportLink}
                    />
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14}>
                      Support Buttom Text
                    </Text>
                    <Text
                      mt={'7px'}
                      color={'#212121'}
                      opacity={0.5}
                      fontWeight={600}
                      fontSize={12}
                    >
                      This link will appear on the support link button if a link
                      has been set.
                    </Text>
                    <Input
                      mt={'8px'}
                      fontSize={13}
                      placeholder="Customer Support"
                      required
                      value={supportButtonText}
                      name="SupportText"
                      onChange={handleSupportButtonText}
                    />
                  </Box>
                  {/* <Box mt={'25px'}>
                                        <Text  fontWeight={500} fontSize={14}>Allow Domain</Text>
                                        <Text mt={'7px'} color={'#212121'} opacity={0.5} fontWeight={600} fontSize={12}>Enter a comma-separated list of domains that are allowed to embed this widget. Any subdomains must be listed seperately. leace black to allow all domains.</Text>
                                        <Input mt={'8px'} fontSize={13} placeholder="myself.com, www.mysite.com, anotherdomain.com, etc" required value={allowDomain} name="SupportText" onChange={handleAllowDomain} />
                                    </Box> */}
                  <Flex mt={'38px'} align={'center'}>
                    <Text fontWeight={500} fontSize={14} mb={2}>
                      Header color:
                    </Text>
                    <Input
                      sx={{
                        '&::-webkit-color-swatch-wrapper': {
                          border: 'none',
                        },
                        '&::-webkit-color-swatch': {
                          borderRadius: '4px',
                          padding: '0',
                          border: '1px solid rgba(0, 0, 0, 0.12)',
                          boxShadow:
                            '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                        },
                      }}
                      border={'none'}
                      width={'100px'}
                      type="color"
                      value={headercolor}
                      onChange={handleHeaderColorChange}
                    />
                  </Flex>
                  <Flex mt={'38px'} align={'center'}>
                    <Text fontWeight={500} fontSize={14} mb={2}>
                      Button color:
                    </Text>
                    <Input
                      sx={{
                        '&::-webkit-color-swatch-wrapper': {
                          border: 'none',
                        },
                        '&::-webkit-color-swatch': {
                          borderRadius: '4px',
                          padding: '0',
                          border: '1px solid rgba(0, 0, 0, 0.12)',
                          boxShadow:
                            '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                        },
                      }}
                      border={'none'}
                      width={'100px'}
                      type="color"
                      value={buttoncolor}
                      onChange={handleButtonColorChange}
                    />
                  </Flex>
                  <Flex mt={'25px'} align={'center'}>
                    <Text fontWeight={500} fontSize={14} mb={2}>
                      Widget color:
                    </Text>
                    <Input
                      sx={{
                        '&::-webkit-color-swatch-wrapper': {
                          border: 'none',
                        },
                        '&::-webkit-color-swatch': {
                          borderRadius: '4px',
                          padding: '0',
                          border: '1px solid rgba(0, 0, 0, 0.12)',
                          boxShadow:
                            '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                        },
                      }}
                      border={'none'}
                      width={'100px'}
                      type="color"
                      value={widgetcolor}
                      onChange={handleWidgetColorChange}
                    />
                  </Flex>
                  <Box mt={'25px'}>
                    <Text fontWeight={700}>Bot message</Text>
                    <Flex mt={'14px'} gap={3} align={'center'}>
                      <Text fontSize={14} mb={2}>
                        Text:
                      </Text>
                      <Input
                        sx={{
                          '&::-webkit-color-swatch-wrapper': {
                            border: 'none',
                          },
                          '&::-webkit-color-swatch': {
                            borderRadius: '4px',
                            padding: '0',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow:
                              '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                          },
                        }}
                        border={'none'}
                        width={'100px'}
                        type="color"
                        value={botTextcolor}
                        onChange={handlebotTextColorChange}
                      />
                    </Flex>
                    <Flex mt={'14px'} align={'center'}>
                      <Text fontSize={14} mb={2}>
                        Background:
                      </Text>
                      <Input
                        border="none"
                        width="100px"
                        type="color"
                        value={botBackgroundcolor}
                        onChange={handlebotBackgroundColorChange}
                        sx={{
                          '&::-webkit-color-swatch-wrapper': {
                            border: 'none',
                          },
                          '&::-webkit-color-swatch': {
                            borderRadius: '4px',
                            padding: '0',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow:
                              '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                          },
                        }}
                      />
                    </Flex>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={700}>User message</Text>
                    <Flex mt={'14px'} align={'center'}>
                      <Text fontSize={14} mb={2}>
                        Text:
                      </Text>
                      <Input
                        sx={{
                          '&::-webkit-color-swatch-wrapper': {
                            border: 'none',
                          },
                          '&::-webkit-color-swatch': {
                            borderRadius: '4px',
                            padding: '0',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow:
                              '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                          },
                        }}
                        border={'none'}
                        width={'100px'}
                        type="color"
                        value={userTextcolor}
                        onChange={handleuserTextColorChange}
                      />
                    </Flex>
                    <Flex mt={'14px'} align={'center'}>
                      <Text fontSize={14} mb={2}>
                        Background:
                      </Text>
                      <Input
                        sx={{
                          '&::-webkit-color-swatch-wrapper': {
                            border: 'none',
                          },
                          '&::-webkit-color-swatch': {
                            borderRadius: '4px',
                            padding: '0',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow:
                              '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                          },
                        }}
                        border={'none'}
                        width={'100px'}
                        type="color"
                        value={userBackgroundcolor}
                        onChange={handleuserBackgroundColorChange}
                      />
                    </Flex>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14} mb={2}>
                      Widget icons
                    </Text>
                    {renderButton('BsFillChatFill', <BsFillChatFill />)}
                    {renderButton('IoIosChatbubbles', <IoIosChatbubbles />)}
                    {renderButton('FaRobot', <FaRobot />)}
                    {renderButton('BiSupport', <BiSupport />)}
                    {renderButton('BsQuestionLg', <BsQuestionLg />)}
                    {renderButton('BiBookBookmark', <BiBookBookmark />)}
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14}>
                      Bot Avatar
                    </Text>

                    {renderAvatar('None')}
                    {renderAvatar('IoIosChatbubbles', <IoIosChatbubbles />)}
                    {renderAvatar('FaRobot', <FaRobot />)}
                    {renderAvatar('BiSupport', <BiSupport />)}
                    {renderAvatar('BsQuestionLg', <BsQuestionLg />)}
                    {renderAvatar('BiBookBookmark', <BiBookBookmark />)}
                  </Box>

                  <Box mt={'25px'}>
                    <Flex
                      alignItems={'center'}
                      fontWeight={500}
                      fontSize={14}
                      mb={2}
                      gap={2}
                    >
                      Citations{' '}
                      <HoverTooltip message="Each chatbot response shows an option for the user to see the sources/citations from your content from which the response was generated." />
                    </Flex>
                    <RadioGroup onChange={setCitation} value={citation}>
                      <Stack direction="row">
                        <Radio colorScheme="blue" value="false" fontSize={13}>
                          Disabled
                        </Radio>
                        <Radio colorScheme="blue" value="true" fontSize={13}>
                          Enabled
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14} mb={2}>
                      Widget alignment
                    </Text>
                    <RadioGroup
                      onChange={setButtonAlignment}
                      value={buttonAlignment}
                    >
                      <Stack direction="row">
                        <Radio colorScheme="blue" value="Left" fontSize={13}>
                          Left
                        </Radio>
                        <Radio colorScheme="blue" value="Right" fontSize={13}>
                          Right
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14} mb={'7px'}>
                      Show buttom text
                    </Text>
                    <Box>
                      <FormControl display={'flex'}>
                        <FormLabel
                          htmlFor="isRequired"
                          color={'#212121'}
                          opacity={0.5}
                          fontWeight={600}
                          fontSize={12}
                        >
                          {' '}
                          Show text next to the floating button icon ?
                        </FormLabel>
                        <Switch
                          colorScheme="blue"
                          id="isRequired"
                          isChecked={buttonTextToggle}
                          onChange={handleToggleSwitch}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14} mb={'7px'}>
                      Show branded text
                    </Text>
                    <Box>
                      <FormControl display={'flex'}>
                        <FormLabel
                          htmlFor="isRequired"
                          color={'#212121'}
                          opacity={0.5}
                          fontWeight={600}
                          fontSize={12}
                        >
                          {' '}
                          Show brand text at the footer of the widget ?
                        </FormLabel>
                        <Switch
                          colorScheme="blue"
                          id="isRequired"
                          isDisabled={!hasAccess?.UNBRANDED_WIDGET.enabled}
                          isChecked={brandingToggle}
                          onChange={handleToggleBranding}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box mt={'25px'}>
                    <Text fontWeight={500} fontSize={14} mb={0}>
                      Buttom text
                    </Text>
                    <Input
                      fontSize={13}
                      placeholder="Help"
                      required
                      name="buttonText"
                      value={buttonText}
                      onChange={handleButtonText}
                    />
                  </Box>
                  {/* <Box mt={3}>
                                        <Text  fontWeight={500} fontSize={14} mb={2}>
                                            Button alignment
                                        </Text>
                                        <RadioGroup onChange={setButtonAlignment} value={buttonAlignment}>
                                            <Stack direction='row'>
                                                <Radio colorScheme="orange" value='Left'>Left</Radio>
                                                <Radio colorScheme="orange" value='Right'>Right</Radio>
                                            </Stack>
                                        </RadioGroup>

                                    </Box> */}
                  {botData && (
                    <FormControl paddingTop={5}>
                      <FormLabel mb={0}>Suggested questions</FormLabel>
                      <Text pb={2} fontSize={13}>
                        A random selection of these sample questions will be
                        shown to users in the chat interfaces. You can create
                        customized questions to suit your company or client
                        needs.
                      </Text>
                      <Flex alignItems="center" flexDirection="column">
                        {firstQuestion &&
                          firstQuestion.map((question) => (
                            <Flex
                              w="100%"
                              key={question.ques_id}
                              alignItems="center"
                              mb={2}
                              gap={3}
                            >
                              <Input
                                fontSize={13}
                                value={question.text}
                                onChange={(e) =>
                                  updateQuestion(
                                    question.ques_id,
                                    e.target.value
                                  )
                                }
                              />
                              <RiDeleteBin6Line
                                color="red"
                                size={20}
                                onClick={() => removeQuestion(question.ques_id)}
                              />
                              {/* <IconButton
                                                            aria-label="remove"
                                                            icon={<RiDeleteBin6Line />}
                                                            colorScheme="red"
                                                            variant="outline"
                                                            onClick={() => removeQuestion(question.ques_id)}
                                                            ml={2}
                                                            size="sm"
                                                        /> */}
                            </Flex>
                          ))}
                      </Flex>
                      <Flex alignItems="center" mt={2}>
                        <Input
                          type="text"
                          fontSize={13}
                          placeholder="Enter new question"
                          value={newQuestion}
                          onChange={(e) => setNewQuestion(e.target.value)}
                          mr={2}
                        />
                        <Button
                          bg={'#002298'}
                          _hover={{ bg: '#002298' }}
                          color={'white'}
                          variant="outline"
                          onClick={() => {
                            if (newQuestion.trim() !== '') {
                              addQuestion({ text: newQuestion });
                            }
                          }}
                          disabled={newQuestion.trim() === ''}
                        >
                          <SmallAddIcon /> Add
                        </Button>
                      </Flex>
                    </FormControl>
                  )}
                </Box>
                <Box mt={3} w={'100%'}>
                  <Flex
                    p={'30px 10px'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    bg={'orange.100'}
                  >
                    <Text
                      pb={2}
                      fontSize={'12px'}
                      mr={'auto'}
                      paddingLeft={'70px'}
                    >
                      Chatbot preview
                    </Text>
                    <Box
                      pr={0}
                      borderRadius={'15px'}
                      bg={'white'}
                      w={'262px'}
                      filter={
                        'drop-shadow(0px 0px 6.60504px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2.20168px 4.40336px rgba(0, 0, 0, 0.1))'
                      }
                    >
                      <ModalHeader
                        borderRadius={'15px  15px   0px 0px'}
                        p={'15px'}
                        bg={headercolor}
                        shadow={
                          '0px 8.71626px 24.1373px -5.36385px rgba(0, 0, 0, 0.25)'
                        }
                      >
                        <Box display={'flex'} gap={3}>
                          {selectedAvatar != 'None' ? (
                            <Avatar
                              size={'sm'}
                              position="relative"
                              fontSize="1.5rem"
                              icon={<IconComponent iconName={selectedAvatar} />}
                            >
                              <AvatarBadge
                                boxSize="0.6em"
                                fontSize="0.6rem"
                                bg="green.500"
                                position="absolute"
                                top="90%"
                                right="0"
                                transform="translateY(-50%)"
                              />
                              {/* <img src={ChatbotLogo} alt="Chatbot Logo" /> */}
                            </Avatar>
                          ) : (
                            <></>
                          )}
                          <Box>
                            <Heading
                              color={'white'}
                              fontSize="13.2101px"
                              size="sm"
                            >
                              {heading}
                            </Heading>
                            <Text
                              fontSize={'11.0084px'}
                              fontWeight={400}
                              color={'white'}
                            >
                              {subHeading}
                            </Text>
                          </Box>
                        </Box>
                        {/* <Text py="2" fontSize="15px">{botData?.description && botData?.description.charAt(0).toUpperCase() + botData?.description.slice(1)}</Text> */}
                      </ModalHeader>

                      <ModalBody
                        pl={0}
                        pr={0}
                        overflow={'auto'}
                        h={'300px'}
                        bg="#E7EBF1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box
                          justifyContent={'flex-end'}
                          p={3}
                          pr={0}
                          borderRadius="md"
                          flex="1"
                          display="flex"
                          flexDirection="column"
                        >
                          {/* Chat messages */}
                          <Box pr={6}>
                            {firstMessage && (
                              <>
                                <Box
                                  width={'fit-content'}
                                  maxW={'430px'}
                                  bg={botBackgroundcolor}
                                  borderRadius={'14px 14px 14px 0px'}
                                  p={2}
                                  mb={2}
                                  ml={0}
                                  alignSelf={'flex-end'}
                                  color={botTextcolor}
                                  fontSize={'11px'}
                                >
                                  <p>{firstMessage}</p>
                                </Box>
                              </>
                            )}
                            <>
                              <Box
                                width={'fit-content'}
                                maxW={'430px'}
                                bg={userBackgroundcolor}
                                borderRadius={'14px 14px 0 14px'}
                                p={2}
                                mb={2}
                                ml={'auto'}
                                alignSelf={'flex-start'}
                                color={userTextcolor}
                                fontSize={'11px'}
                              >
                                <p>How to add chatbot to my website</p>
                              </Box>
                            </>
                            <>
                              <Box
                                width={'fit-content'}
                                maxW={'430px'}
                                bg={botBackgroundcolor}
                                borderRadius={'14px 14px 14px 0px'}
                                p={2}
                                mb={2}
                                ml={0}
                                alignSelf={'flex-end'}
                                color={botTextcolor}
                                fontSize={'11px'}
                              >
                                <p>
                                  Please go to "Widget Embed" inside your
                                  chatbot to copy your embedding code. Add this
                                  code to your HTML page before the closing{' '}
                                  {`</body>`} tag.
                                </p>
                              </Box>
                            </>
                            {supportLink && (
                              <>
                                <Box
                                  // w={'fit-content'}
                                  // border={'1.10084px solid rgba(85, 85, 85, 0.15)'}
                                  // borderRadius={'6.60504px'}
                                  // p={1}
                                  // ml={'80%'}
                                  // alignSelf={'flex-end'}
                                  // fontSize={'11px'}
                                  w={'fit-content'}
                                  border={
                                    '1.10084px solid rgba(85, 85, 85, 0.15)'
                                  }
                                  borderRadius={'6.60504px'}
                                  p={1}
                                  ml={'auto'}
                                  alignSelf={'flex-start'}
                                  fontSize={'11px'}
                                  cursor={'pointer'}
                                >
                                  <p>
                                    {supportButtonText
                                      ? supportButtonText
                                      : supportLink}
                                  </p>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </ModalBody>
                      <Box borderRadius={' 0 0 12px 12px'} pt={1} pb={1}>
                        <InputGroup size="sm" pb={1}>
                          <Input
                            fontSize={'12px'}
                            placeholder="Type your message..."
                            border={'none'}
                            flex="1"
                            bg={'white'}
                            borderRadius={0}
                            pr={'50px'}
                            readOnly
                            _focus={{ boxShadow: 'none' }}
                            _hover={{ boxShadow: 'none' }}
                            _active={{ boxShadow: 'none' }}
                          />
                          <InputRightElement width="4.5rem" color={'#D2D2D2'}>
                            {/* <Img src={SendIcon}  onClick={handleMessageSubmit} /> */}
                            <RiSendPlane2Fill size={16} />
                          </InputRightElement>
                        </InputGroup>
                        {brandingToggle && (
                          <Box
                            borderTop={'1px solid lightgray'}
                            pt={'1.5px'}
                            pb={'1.5px'}
                            mt={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            fontSize={'10px'}
                            color={'gray.500'}
                            fontWeight={500}
                            gap={1}
                          >
                            <Text>Powered by</Text>
                            {/* <HiChatBubbleLeftRight /> */}
                            <Text color={'black'} fontWeight={700}>
                              Bambu.ai
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box mt={2} ml={'auto'} pr={'70px'}>
                      {buttonText && buttonTextToggle ? (
                        <Button
                          _hover={''}
                          leftIcon={<IconComponent iconName={selectedIcon} />}
                          borderRadius={50}
                          color={widgetcolor}
                          bg={buttoncolor}
                          variant={buttonText ? 'solid' : 'round'}
                        >
                          {buttonText}
                        </Button>
                      ) : (
                        <IconButton
                          size="lg"
                          fontSize="25px"
                          icon={<IconComponent iconName={selectedIcon} />}
                          bg={buttoncolor}
                          color={widgetcolor}
                          borderRadius={50}
                          _hover={''}
                        />
                      )}
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter justifyContent={'center'} gap={3}>
            <Button
              w={'130px'}
              border={'1px solid rgba(33, 33, 33, 0.15)'}
              h={'34px'}
              borderRadius={'65px'}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              _hover={{ bg: '#091D62' }}
              w={'130px'}
              h={'34px'}
              borderRadius={'65px'}
              isLoading={isSubmitting}
              type="submit"
              bg="#002298"
              color={'white'}
              mr={3}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default WidgetCustomize;
