import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  Image,
  Stack,
  InputGroup,
  Input,
  InputRightElement,
  Center,
  useToast,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { BsArrowRight, BsCheckCircle, BsRobot } from "react-icons/bs";
import { HiOutlineCheckBadge, HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { AiOutlineQuestionCircle, AiOutlineRight } from "react-icons/ai";

import BotIcon from "../assets/dashboard/botIcon.png";

import SourcePageIcon from "../assets/dashboard/SourcePageIcon.png";
import CurrentPlanIcon from "../assets/dashboard/currentPlanIcon.png";

import { useNavigate } from "react-router-dom";
import { FaRegCreditCard, FaRegQuestionCircle } from "react-icons/fa";
import CardImg from "../assets/account/cardimg.png";

import { ReactComponent as BillingCardIconDark } from "../assets/account/card.svg";
import { ReactComponent as BillingCardIconLight } from "../assets/account/card1.svg";

import { ReactComponent as UpdateEmailIcon } from "../assets/account/Frame 43092.svg";
import { ReactComponent as DeleteTeamAccountIcon } from "../assets/account/Frame 43092 (1).svg";
import { AppContext } from "../utils/AppContext";
import { WarningTwoIcon } from "@chakra-ui/icons";
import ShowConfirmationModal from "./Account_components/ShowConfirmationModal";
import FetchWithToken from "../utils/FetchWithToken";
import { logDOM } from "@testing-library/react";
import { t } from "i18next";

const Account = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const LOCAL_BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const STRIPE_PRICINGTABLE_ID = process.env.REACT_APP_STRIPE_PRICINGTABLE_ID;
  const STRIPE_ENTERPRISETABLE_ID =
    process.env.REACT_APP_STRIPE_ENTERPRISE_PRICINGTABLE_ID;
  // const ePID = "prctbl_1MWiKISIAHzWj0AD515AnDD9";

  const toast = useToast();

  const [currentPackage, setCurrentPackage] = useState();
  const [teamSubscription, setTeamSubscription] = useState();
  const [showEnterpriseChart, setShowEnterpriseChart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const { globalTeam, setGlobalTeam} = useContext(AppContext);
  // const globalTeam = JSON.parse(localStorage.getItem('globalTeam'));
  const user_id = JSON.parse(localStorage.getItem("docsbot-users"))?.user_id;
  const selectedTeam = JSON.parse(
    localStorage.getItem("docsbot-users")
  )?.selectedTeam;

  const navigate = useNavigate();
  function fetchTeamSubcriptionData() {
    // setIsLoading(true);
    FetchWithToken(
      BASE_URL + `/stats?team_id=${selectedTeam.team_id}`,
      "GET"
    ).then((data) => {
      setTeamSubscription(data);
    });
  }

  function fetchCurrentPackage() {
    const plan_name = teamSubscription?.current_plan;
    // setIsLoading(true);
    FetchWithToken(BASE_URL + `/packages?plan=${plan_name}`, "GET").then(
      (data) => {
        setCurrentPackage(data.features);
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    fetchTeamSubcriptionData();
  }, []);
  useEffect(() => {
    teamSubscription && fetchCurrentPackage();
  }, [teamSubscription]);

  const handleEnterpriseChartShow = () => {
    setShowEnterpriseChart(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleDeleteTeam = async () => {
    setShowConfirmModal(true);
  };
  const SubscriptionDetails = () => {
    return (
      <Box>
        <SimpleGrid
          spacing={4}
          templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
        >
          <Card height="130px" bg={"rgba(228, 246, 242, 0.82)"}>
            <CardBody
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              padding={"0px 15px"}
            >
              <Box padding="0">
                <Box display="flex" alignItems={"center"}>
                  <Text fontWeight={700} fontSize={36}>
                    {teamSubscription?.current_plan}
                  </Text>
                </Box>
              </Box>
              <Box display={"flex"} h={"100%"} alignItems={"center"}>
                <Image src={CurrentPlanIcon} alt="BotIcon" />
              </Box>
            </CardBody>
            <CardFooter
              justifyContent="space-between"
              padding={"0px 10px 13px 10px"}
            >
              <Text fontWeight={500} fontSize={"15px"} color={"#212121"}>
                {t("Accounts.current_plan")}
              </Text>
            </CardFooter>
          </Card>

          <Card height="130px" bg={"rgba(64, 140, 253, 0.08)"}>
            <CardBody
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              padding={"0px 15px"}
            >
              <Box padding="0">
                <Box display="flex" alignItems={"center"}>
                  <Text fontWeight={700} fontSize={36}>
                    {currentPackage?.BOTS.count}
                  </Text>
                </Box>
              </Box>
              <Box display={"flex"} h={"100%"} alignItems={"center"}>
                {/* <BsRobot color={"blue"} size={24} /> */}
                <Image src={BotIcon} alt="BotIcon" />
              </Box>
            </CardBody>
            <CardFooter
              justifyContent="space-between"
              padding={"0px 10px 13px 10px"}
            >
              <Text fontWeight={500} color={"#212121"}>
                {t("Accounts.all_bots")}
                {/* All Bots */}
              </Text>
            </CardFooter>
          </Card>

          <Card height="130px" bg={"rgba(233, 225, 254, 0.4)"}>
            <CardBody
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              padding={"0px 15px"}
            >
              <Box padding="0">
                <Box display="flex" alignItems={"center"}>
                  <Text fontWeight={700} fontSize={36}>
                    {currentPackage?.SOURCE_PAGES.count >= 1000
                      ? currentPackage?.SOURCE_PAGES.count / 1000 + "K"
                      : currentPackage?.SOURCE_PAGES.count}
                  </Text>
                  {/* <Text pt={'12px'}>/10000</Text> */}
                </Box>
              </Box>
              <Box display={"flex"} h={"100%"} alignItems={"center"}>
                <Image src={SourcePageIcon} alt="BotIcon" />
              </Box>
            </CardBody>
            <CardFooter
              justifyContent="space-between"
              padding={"0px 10px 13px 10px"}
            >
              <Text fontWeight={500} fontSize={"15px"} color={"#212121"}>
                {t("Accounts.source_pages")}
                {/* Souce pages */}
              </Text>
            </CardFooter>
          </Card>

          <Card height="130px" bg={"rgba(255, 62, 24, 0.05)"}>
            <CardBody
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              padding={"0px 15px"}
            >
              <Box padding="0">
                <Box display="flex" alignItems={"center"}>
                  <Text fontWeight={700} fontSize={36}>
                    {currentPackage?.QUESTIONS.count >= 1000
                      ? currentPackage?.QUESTIONS.count / 1000 + "K"
                      : currentPackage?.QUESTIONS.count}
                  </Text>
                  {/* <Text pt={'12px'}>/10000</Text> */}
                </Box>
              </Box>
              <Box display={"flex"} h={"100%"} alignItems={"center"}>
                <FaRegQuestionCircle color={"red"} size={24} />
              </Box>
            </CardBody>
            <CardFooter
              justifyContent="space-between"
              padding={"0px 10px 13px 10px"}
            >
              <Text fontWeight={500} fontSize={"15px"} color={"#212121"}>
                {t("Accounts.questions")}
                {/* Questions */}
              </Text>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </Box>
    );
  };

  const storedData = localStorage.getItem("docsbot-users");

  const userRole = selectedTeam.user_role;
  const isDisabled = userRole === "owner" ? false : true;

  const handlePortalSubmit = () => {};

  return (
    <>
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
          <Spinner color="blue.500" />
        </Box>
      ) : (
        <Box>
          <SubscriptionDetails />
          <Box pt={4}>
            {teamSubscription?.current_plan === "Free" &&
            userRole === "owner" ? (
              <Box p={4} bg={"white"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Heading mb={"10px"}>{t("Accounts.Choose a plan")}.</Heading>
                  <Text fontSize="md">
                    {t(
                      "Choose a plan that best fits your needs. You can upgrade or downgrade at any time"
                    )}
                    .
                  </Text>
                  <Text fontSize="lg" color={"#002298"}>
                    * {t("Accounts.14-Day money-back guarantee")}!
                  </Text>
                  <Text fontSize="lg" my={"10px"}>
                    {t("Accounts.Get two months free with all Yearly Plans")}
                  </Text>
                </Box>
                <stripe-pricing-table
                  pricing-table-id={STRIPE_PRICINGTABLE_ID}
                  publishable-key={STRIPE_PUBLISHABLE_KEY}
                  client-reference-id={JSON.parse(storedData).user_id}
                  customer-email={JSON.parse(storedData).email}
                ></stripe-pricing-table>
                <Center mb={"5px"}>
                  <Text fontSize="xs">
                    {t("Accounts.Need More")}?{" "}
                    <Button
                      onClick={handleEnterpriseChartShow}
                      size={"xs"}
                      _hover={{ color: "#002298" }}
                      color={"#002298"}
                      variant="link"
                    >
                      {t("Accounts.View our Entriprise options")}
                    </Button>
                  </Text>
                </Center>
                {showEnterpriseChart && (
                  <>
                    {/* change table */}

                    <stripe-pricing-table
                      pricing-table-id={STRIPE_ENTERPRISETABLE_ID}
                      publishable-key={STRIPE_PUBLISHABLE_KEY}
                      client-reference-id={JSON.parse(storedData).user_id}
                      customer-email={JSON.parse(storedData).email}
                    ></stripe-pricing-table>
                  </>
                )}
              </Box>
            ) : (
              <Card mt={6} direction={"row"} shadow={0} borderRadius={"12px"}>
                <Box alignSelf={"center"}>
                  <Image src={CardImg} alt="CardImg" />
                </Box>
                <Stack w={"100%"}>
                  <CardBody
                    display={"flex"}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Heading size="md">
                        {t("Accounts.Manage your plan")}
                      </Heading>
                      <Text py="2" pt={"11px"} fontSize={"14px"}>
                        {t(
                          "Accounts.You are currently on the  Plan. Access our billing portal to modify your plan, update payment methods, download invoices, or cancel your subscription.",
                          { subscription: teamSubscription?.current_plan }
                        )}
                      </Text>
                    </Box>
                    <form
                      method="POST"
                      action={BASE_URL + "/create-customer-portal-session"}
                    >
                      <input
                        name="user_id"
                        defaultValue={JSON.parse(storedData).user_id}
                        hidden
                      ></input>
                      {/* <Button colorScheme="teal" type="submit">
                Manage billing
              </Button> */}

                      <Button
                        isDisabled={isDisabled}
                        type="submit"
                        gap={1}
                        leftIcon={<FaRegCreditCard size={"18px"} />}
                        fontSize={"14px"}
                        _hover={{
                          bg: "var(--Web-color-orange)",
                          color: "white",
                        }}
                        bg={""}
                        h={"34px"}
                        border={"1px solid rgba(33, 33, 33, 0.55)"}
                        borderRadius={"65px"}
                      >
                        {t("Accounts.BillingPortal")}
                      </Button>
                    </form>
                  </CardBody>
                </Stack>
              </Card>
            )}
          </Box>
          <SimpleGrid
            spacing={4}
            templateColumns={{
              base: "repeat(auto-fill, minmax(350px, 1fr))",
              md: "repeat(auto-fill, minmax(532px, 1fr))",
            }}
          >
            <Card mt={6} direction="row" shadow={0} borderRadius="12px">
              <Box alignSelf="start" p={"18px"}>
                <UpdateEmailIcon />
              </Box>
              <Stack w="100%">
                <CardBody pl={0}>
                  <Heading size="md">
                    {t("Accounts.Update your email address")}
                  </Heading>
                  <Text py="2" fontSize="13px" lineHeight={"150%"}>
                    {t(
                      "Accounts.You can update your email address here. This will update your email address for all of your teams."
                    )}
                  </Text>
                  <InputGroup size="md">
                    <Input
                      placeholder={t("Accounts.Enter New Email Address")}
                      fontSize={"14px"}
                      borderRadius="48px"
                      flex="1"
                      mr={2}
                      pr={"50px"}
                      bg={"rgba(0, 0, 0, 0.03)"}
                    />
                    <InputRightElement width="87px" p={2} pr={3}>
                      <Button
                        _hover={{
                          shadow:
                            "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
                          bg: "",
                        }}
                        h={"34px"}
                        bg={"white"}
                        color={"black"}
                        borderRadius="48px"
                        fontSize={"14px"}
                      >
                        {t("Accounts.Update")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </CardBody>
              </Stack>
            </Card>

            <Card mt={6} direction="row" shadow={0} borderRadius="12px">
              <Box alignSelf="start" p={"18px"}>
                <DeleteTeamAccountIcon />
              </Box>
              <Stack w="100%">
                <CardBody pl={0}>
                  <Box>
                    <Heading size="md">
                      {t("Accounts.Delete team account")}
                    </Heading>
                    <Text py="2" fontSize="13px" lineHeight={"150%"}>
                      {t(
                        "Accounts.To delete your team account, click delete below. Please note that this will permanently delete all your bots, sources, questions, and other data, as well as remove all team members. This action is irreversible, so make sure to cancel any subscription before proceeding with the deletion of your team account."
                      )}
                    </Text>
                  </Box>
                  <Box w={"100%"} display={"flex"} justifyContent={"flex-end"}>
                    <Button
                      isDisabled={isDisabled}
                      onClick={handleDeleteTeam}
                      gap={1}
                      fontSize="14px"
                      _hover={{ bg: "#D4443B", color: "white" }}
                      bg="#D4443B"
                      color={"white"}
                      w="116px"
                      h="34px"
                      border="1px solid rgba(33, 33, 33, 0.55)"
                      borderRadius="65px"
                    >
                      <Text>{t("Accounts.Delete")}</Text>
                    </Button>
                  </Box>
                </CardBody>
              </Stack>
            </Card>
          </SimpleGrid>

          <ShowConfirmationModal
            isOpen={showConfirmModal}
            onClose={handleConfirmationModalClose}
          />
        </Box>
      )}
    </>
  );
};

export default Account;
