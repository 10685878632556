import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const AnonmyousRoute = ({ children, ...rest }) => {
  const isAuthenticated = localStorage.getItem('docsbot-users') ? true : false;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null; // Prevent rendering children until navigation completes
  }

  return <>{children}</>;
};

export default AnonmyousRoute;
