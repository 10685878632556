import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  InputLeftElement,
  Box,
  Card,
  CardBody,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  Select,
  Text,
  Avatar,
  Divider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AddIcon, DeleteIcon, EmailIcon } from "@chakra-ui/icons";
import { FaPlus, FaUser } from "react-icons/fa";
import { MdEmail, MdOutlineEmail } from "react-icons/md";
import { PricingChartModel } from "./PricingChartModel";
import { color } from "framer-motion";
import withAccessControl from "../utils/withAccessControl";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../utils/AppContext";
import FetchWithToken from "../utils/FetchWithToken";
import { t } from "i18next";

const Team = ({ hasAccess, handleCallback }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [selectedTeam, setSelectedTeam] = useState(
    JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam
  );
  const [subscription, setsubscription] = useState();
  const [teamData, setTeamData] = useState();
  const [showInviteButton, setshowInviteButton] = useState(false);
  const [inviteEmail, setInviteEmail] = useState();
  const [team_name, setTeamName] = useState();
  const [newTeamName, setNewTeamName] = useState();
  const [teamsList, setTeamsList] = useState(
    JSON.parse(localStorage.getItem("docsbot-users")).team_details
  );

  // const { globalTeam, setGlobalTeam} = useContext(AppContext);
  // const storedGlobalTeam = JSON.parse(localStorage.getItem('globalTeam'));
  const toast = useToast();

  const navigate = useNavigate();

  const [showPricingModal, setShowPricingModal] = useState(false);

  // const teamsList = JSON.parse(localStorage.getItem("docsbot-users")).team_details;
  // const selectedTeam = JSON.parse(localStorage.getItem("docsbot-users")).selectedTeam;

  const userRole = selectedTeam?.user_role;
  const isInviteDisabled = userRole === "owner" ? false : true;

  function fetchTeam() {
    // Reset password API call or further processing
    FetchWithToken(
      BASE_URL + "/team?team_id=" + selectedTeam?.team_id,
      "GET"
    ).then((data) => {
      setTeamData(data.Teams);
      setTeamName(data.team_name);
    });
  }

  useEffect(() => {
    const subscription = JSON.parse(
      localStorage.getItem("docsbot-users")
    ).subscription;
    setsubscription(subscription);
    fetchTeam(); //get team details
  }, [selectedTeam]);

  const handleShowPricingModalClick = () => {
    setShowPricingModal(true);
  };
  const handleShowPricingModlClose = () => {
    setShowPricingModal(false);
  };

  const handleTeamChange = (event) => {
    setSelectedTeam(JSON.parse(event.target.value));

    var existingDataString = localStorage.getItem("docsbot-users");
    var existingData = JSON.parse(existingDataString);
    existingData.selectedTeam = JSON.parse(event.target.value);
    var updatedDataString = JSON.stringify(existingData);
    localStorage.setItem("docsbot-users", updatedDataString);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("docsbot-users");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedTeam(parsedData.selectedTeam);
    }
  }, []);

  const handleTeamInvite = (event) => {
    event.preventDefault();
    if (hasAccess.USERS.enabled) {
      const data = {
        email: inviteEmail,
        team_id: selectedTeam?.team_id,
        team_name: team_name,
      };
      FetchWithToken(BASE_URL + "/team/members", "POST", data).then((data) => {
        setInviteEmail("");
        toast({
          title: "Invite Link has been sent",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        handleCallback();
      });
    } else {
      setShowPricingModal(true);
    }
  };

  function updateTeamDetails(updatedTeam) {
    setTeamsList((prevObjects) =>
      prevObjects.map((obj) =>
        obj.team_id === updatedTeam.team_id ? updatedTeam : obj
      )
    );
    //update selected team details
    var user_details = JSON.parse(localStorage.getItem("docsbot-users"));
    user_details.selectedTeam = updatedTeam;
    localStorage.setItem("docsbot-users", JSON.stringify(user_details));
    // }
  }
  useEffect(() => {
    // Update `team_details` in local storage
    var user_details = JSON.parse(localStorage.getItem("docsbot-users"));
    user_details.team_details = teamsList;
    localStorage.setItem("docsbot-users", JSON.stringify(user_details));
  }, [teamsList]);

  const handleTeamNameUpdate = async () => {
    const data = {
      team_id: selectedTeam.team_id,
      team_name: newTeamName,
      user_role: selectedTeam.user_role,
    };

    FetchWithToken(BASE_URL + "/teams/update", "PUT", data).then((data) => {
      if (data.message === "success") {
        toast({
          title: "Team name Updated Successfully",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });

        updateTeamDetails(data.updatedTeamDetail);
      } else {
        toast({
          title: data.message,
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
      }
    });
  };

  const handleTeamNameChange = (event) => {
    setNewTeamName(event.target.value);
  };

  const handleAddMember = () => {
    setshowInviteButton(true);
  };
  return (
    <Box>
      <Box>
        <Card mb={5}>
          <CardBody
            display={"flex"}
            flexDirection={{ base: "column", md: "row" }}
            gap={5}
          >
            <Box w={{ base: "fit-content", md: "70%" }}>
              <Text fontWeight={500} mb={2}>
                {/* Current team */}
                {t("Teams.current_team")}
              </Text>
              <Text color="#979797" fontSize={"14px"}>
                {t(
                  "Teams.Switch between different team dashboard that you have access to"
                )}
                .
              </Text>
              <Select
                value={selectedTeam ? JSON.stringify(selectedTeam) : ""}
                onChange={handleTeamChange}
              >
                {teamsList.map((team, index) => (
                  <option key={team.team_id} value={JSON.stringify(team)}>
                    {team.team_name}
                  </option>
                ))}
                {/* <option selected key={team_name} value={team_name}>
                {team_name}
              </option> */}
              </Select>
            </Box>
            <Box w={{ base: "fit-content", md: "30%" }}>
              <Text fontWeight={500} mb={2}>
                {/* Rename team */}
                {t("Teams.rename_team")}
              </Text>
              <Text color="#979797" fontSize={"14px"}>
                {t("Teams.Enter a new team name for")} {team_name}
              </Text>
              <InputGroup>
                <Input
                  value={newTeamName}
                  onChange={handleTeamNameChange}
                  type="text"
                  placeholder={t("Teams.Enter team name")}
                  borderRightRadius={"0"}
                />
                <Button
                  onClick={handleTeamNameUpdate}
                  disabled={true}
                  border={"1px solid #212121"}
                  variant={"outline"}
                  borderLeftRadius={"0"}
                >
                  {t("Teams.update")}
                  {/* Update */}
                </Button>
              </InputGroup>
            </Box>
          </CardBody>
        </Card>
        {showInviteButton && (
          <Card mb={5}>
            <form onSubmit={handleTeamInvite}>
              <CardBody
                display="flex"
                flexDirection="column"
                alignItems="start"
              >
                <Text fontWeight={500} mb={2}>
                  {t("Teams.Invite a team member")}
                  {/* Invite a team member */}
                </Text>
                <Input
                  type="email"
                  width="auto"
                  mb={2}
                  required
                  value={inviteEmail}
                  placeholder="john@example.com"
                  onChange={(e) => setInviteEmail(e.target.value)}
                />
                <HStack spacing={2}>
                  <Button
                    onClick={() => setInviteEmail("")}
                    colorScheme="gray"
                    variant="outline"
                  >
                    {t("Teams.cancel")}
                    {/* Cancel */}
                  </Button>
                  {/* change the button to the code below */}
                  {/* <Button bg={'var(--Web-color-orange)'} color={'white'} type="submit" _hover={{ bg: 'var(--Web-color-orange)' }}>
                  Invite
                </Button> */}

                  <Button
                    bg={"#000F40"}
                    color={"white"}
                    type="submit"
                    _hover={{ bg: "#002298" }}
                  >
                    {/* Invite */}
                    {t("Teams.invite")}
                  </Button>
                </HStack>
              </CardBody>
            </form>
          </Card>
        )}
        <Card mb={5}>
          <CardBody display="flex" flexDirection="column" alignItems="start">
            <Box
              w={"100%"}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Text fontSize="lg" fontWeight="bold">
                {/* Team Information */}
                {t("Teams.team_information")}
              </Text>
              <Button
                isDisabled={isInviteDisabled}
                onClick={handleAddMember}
                // bg={"var(--Web-color-orange)"}
                bg={"#002298"}
                color={"white"}
                // _hover={{ bg: "var(--Web-color-orange)" }}
                _hover={{ bg: "#002298" }}
                leftIcon={<FaPlus />}
              >
                {/* Add Member */}
                {t("Teams.add_member")}
              </Button>
            </Box>
            <Divider />
            <Table variant="simple">
              <Tbody>
                {teamData?.map((member, index) => (
                  <Tr key={index}>
                    <Td display={"flex"} gap={3}>
                      <Avatar name={member.email} />
                      <Box display={"flex"} alignItems={"center"}>
                        {/* <Text fontWeight="bold" color={"teal"}>
                        {member.name}
                      </Text> */}
                        <Text
                          fontSize={"15px"}
                          display={"flex"}
                          alignItems={"end"}
                          gap={1}
                        >
                          <MdOutlineEmail size={18} />
                          {member.email}
                        </Text>
                      </Box>
                    </Td>
                    <Td textAlign={"end"}>
                      <Text fontSize={"14px"}>{t("Teams.role")}</Text>
                      <Text fontWeight="bold">
                        {member.role.charAt(0).toUpperCase() +
                          member.role.slice(1)}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Box>
      {/* //Pricing Modals */}
      {showPricingModal && (
        <PricingChartModel
          isOpen={showPricingModal}
          onClose={handleShowPricingModlClose}
        />
      )}
    </Box>
  );
};

const TeamWithAccessControl = withAccessControl(Team);
export default TeamWithAccessControl;
