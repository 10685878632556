import React from "react";
// import "./style.css";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Image,
  Square,
  Tag,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { BsDot } from "react-icons/bs";
import { GoStack } from "react-icons/go";
import IndexPageIcon from "../../../assets/chatbot/cardSourcesIcon.svg";
import QA from "../../../assets/chatbot/QA.png";
import urlIcon from "../../../assets/chatbot/URL.png";
import urlList from "../../../assets/chatbot/url_list.png";
import document from "../../../assets/chatbot/Document.png";
import rssFeedIcon from "../../../assets/chatbot/RssfeedIcon.png";
import FilepdfIcon from "../../../assets/chatbot/filePdf.png";
import sitemap from "../../../assets/chatbot/SiteMap.png";
import { RiStackLine } from "react-icons/ri";
import { ImStack } from "react-icons/im";

export const SourcesList = ({
  sourceDetails,
  sourceCategoryDetails,
  setSelectedSource,
  setSourceId,
  setShowSoucesModal,
}) => {
  const handleOpenSourceCrawlerModal = () => {
    // setSelectedSource(source);
    // setSourceId(source.source_id);
    setShowSoucesModal(true);
  };

  const getStatusBadge = (statusValue) => {
    const STATUS_CONFIG = {
      Pending: { label: "Pending", colorScheme: "blue" },
      Scrapped: { label: "Scrapped", colorScheme: "orange" },
      Processing: { label: "Processing", colorScheme: "blue" },
      Processed: { label: "Processed", colorScheme: "orange" },
      Scanned: { label: "Scanned", colorScheme: "orange" },
      Training: { label: "Training", colorScheme: "blue" },
      Trained: { label: "Trained", colorScheme: "orange" },
    };

    const statusConfig = STATUS_CONFIG[statusValue];

    if (statusConfig) {
      const { label, colorScheme } = statusConfig;
      return (
        <Tag
          variant="subtle"
          fontSize={"11px"}
          fontWeight={500}
          colorScheme={colorScheme}
          borderRadius={"60px"}
          p={"6px 12px 6px 12px"}
        >
          {label}
        </Tag>
      );
    }

    return <></>;
  };
  return (
    <Card
      shadow={0}
      w={"100%"}
      h={"100%"}
      overflow={"auto"}
      css={{
        "&::-webkit-scrollbar": {
          width: "6px", // Set the width of the scrollbar
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#4A5568", // Set the color of the scrollbar thumb
        },
      }}
    >
      <CardHeader pb={0}>
        <Box pb={3} display={"flex"} justifyContent={"space-between"}>
          <Text fontSize={18} fontWeight={500} color="#212121">
            {/* Sources */}
            {t("Chatbots.Details.Sources")}
          </Text>

          {/* Manage Sources */}
          <Box
            _hover={{ color: "#002298" }}
            display="flex"
            alignItems="center"
            gap={2.3}
            fontSize={"12px"}
            cursor="pointer"
            onClick={handleOpenSourceCrawlerModal}
          >
            <Text>{t("Chatbots.Details.Manage_Sources")}</Text>
            <ImStack size={18} />
          </Box>
        </Box>
        <Divider />
      </CardHeader>
      <CardBody pr={"5px"}>
        <Flex direction={"column"} gap={2}>
          {sourceCategoryDetails && sourceCategoryDetails.length > 0 ? (
            <>
              {sourceCategoryDetails.map((source, index) => (
                <Box key={index}>
                  <Box gap={1} display="flex" alignItems={"center"} py={1}>
                    <Square
                      size="31px"
                      border={"0.5px solid"}
                      borderColor={"gray.100"}
                      borderRadius={"7px"}
                      color="white"
                    >
                      {source.source_type === "url" && (
                        <Image src={urlIcon} alt="BotIcon" />
                      )}
                      {source.source_type === "sitemap" && (
                        <Image src={sitemap} alt="BotIcon" />
                      )}
                      {source.source_type === "rss_feed" && (
                        <Image src={rssFeedIcon} alt="BotIcon" />
                      )}
                      {source.source_type === "url_list" && (
                        <Image src={urlList} alt="BotIcon" />
                      )}
                      {source.source_type === "Q&A" && (
                        <Image src={QA} alt="BotIcon" />
                      )}
                      {source.source_type === "file" && (
                        <Image src={FilepdfIcon} alt="BotIcon" />
                      )}
                      {source.source_type === "document" && (
                        <Image src={document} alt="BotIcon" />
                      )}
                    </Square>
                    <Box width={"full"}>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box
                          display="flex"
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Heading
                            size="s"
                            fontSize={"14px"}
                            fontWeight={500}
                            color="#202020"
                            fontFamily={"satoshi"}
                          >
                            {source.source_type &&
                              source.source_type
                                .toUpperCase()
                                .replace(/_/g, " ")}
                          </Heading>
                          <BsDot color="#212121" opacity={0.4} />
                          {source.total_page_count && (
                            <Text
                              fontSize="12px"
                              color="#212121"
                              mr={3}
                              opacity={0.4}
                            >
                              {`${source.total_page_count} Pages`}
                            </Text>
                          )}
                        </Box>
                        <Flex>{getStatusBadge(source.category_status)}</Flex>
                      </Flex>
                    </Box>
                  </Box>
                  {/* divider */}
                  <Flex justifyContent={"end"} width={"100%"}>
                    <Divider width={"85%"} orientation="horizontal" />
                  </Flex>
                </Box>
              ))}
            </>
          ) : (
            <Text>{t("Chatbots.Details.No sources available")}.</Text>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};
